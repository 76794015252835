import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/Auth.Context";
import { handlePopupToggle } from "../../../utils/handlePopup";
import PopupFormat from "../../popups/popupFormat";
import FormProyect from "../../../forms/proyectForms/formEditProyects";
import Loader from "../../loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import FormConfirmDeleteProyect from '../../../forms/proyectForms/formConfirmDeleteProyect';
import { topFunction } from '../../../utils/goTop';

const ProyectCardFormat = ({
    mapLocationTitle,
    mapLocationDescription,
    mapLocationImage,
    mapLocationID,
    mapLocationInfoList
}) => {
    const { user, loading } = useAuth();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupType, setPopupType] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    const handlePopupToggle = (type, id) => {
        setPopupType(type);
        setSelectedId(id);
        setPopupOpen(!isPopupOpen);
    }

    return (
        <>
            <div className="project-card-format-content">
                {loading ? (
                    <p><Loader /></p>
                ) : (
                    <>
                        <h1>{mapLocationTitle}</h1>
                        <hr />
                        {mapLocationImage}
                    </>
                )}
                {loading ? (
                    <p><Loader /></p>
                ) : (
                    <>
                        <p className="map-loc-description">{mapLocationDescription}</p>
                    </>
                )}
                {user && user.role === "TRUE" && (
                    <div className='vision-edit-container'>
                        <div>
                            <p
                                className="vision-edit-project"
                                onClick={() => handlePopupToggle('edit', mapLocationID)}
                                disabled={isPopupOpen}
                            >
                                Editar proyecto
                            </p>
                        </div>
                        <div>
                            <p
                                className="vision-edit-project"
                                onClick={() => handlePopupToggle('delete', mapLocationID)}
                                disabled={isPopupOpen}
                            >
                                Eliminar proyecto
                            </p>
                        </div>
                    </div>
                )}
                <Link onClick={topFunction} to={`/portafolio/${mapLocationID}`} className="project-see-more" >
                    Ver mas
                    <FontAwesomeIcon className='fa-angle-right' icon={faAngleRight} />
                </Link>
                {isPopupOpen && (
                    <PopupFormat
                        togglePopup={() => setPopupOpen(!isPopupOpen)}
                        isPopupOpen={isPopupOpen}
                        modifyName={popupType === 'edit' ? "Modificar proyecto" : "Eliminar proyecto"}
                        formPopup={popupType === 'edit' ? <FormProyect selectedId={selectedId} /> : <FormConfirmDeleteProyect selectedId={selectedId} proyectInfoList={mapLocationInfoList} handleClose={() => setPopupOpen(false)} />}
                    />
                )}
            </div>
        </>
    );
};

export default ProyectCardFormat;
