import "../../../styles/styles.css";
import React, { useState } from 'react';
import { useAuth } from "../../../context/Auth.Context";
import { handlePopupToggle } from "../../../utils/handlePopup";
import PopupFormat from "../../popups/popupFormat";
import FormHomeCard from "../../../forms/specialityForms/formEditSpecialityCard";
import { useSpecialityCard } from "../../../context/SpecialityCards.Context";
import Loader from "../../loader";
import FormConfirmEspecialidad from "../../../forms/specialityForms/formDeleteEspecialidad";
const SpecialityFormat1 = ({
    cardTitle,
    cardContent,
    cardImage,
    cardID
}) => {
    const { user } = useAuth();
    const { loading } = useSpecialityCard();
    const [isInteractionDisabled, setInteractionDisabled] = useState(false);
    const [isDeleteInteractionDisabled, setDeleteInteractionDisabled] = useState(false);
    const [isDeleteContentPopupOpen, setIsDeleteContentPopupOpen] = useState(false);
    const [isCardPopupOpen, setIsCardPopupOpen] = useState(false);

    const handleCardPopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsCardPopupOpen, isInteractionDisabled, isCardPopupOpen);
    }

    const handleDeleteContentPopupToggle = () => {
        console.log("handleDeleteContentPopupToggle executed");
        console.log("isDeleteContentPopupOpen:", isDeleteContentPopupOpen);
        handlePopupToggle(setDeleteInteractionDisabled, setIsDeleteContentPopupOpen, isDeleteInteractionDisabled, isDeleteContentPopupOpen);
    };
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="summary-container">
                    <div className="summary">
                        <label for="texto-resumen">
                            {loading ? (
                                <b>Cargando...</b>
                            ) : (
                                <b>{cardTitle}</b>
                            )}
                            {user && user.role === "TRUE" && (
                                <>
                                    <p
                                        className="home-info-edit"
                                        onClick={() => handleCardPopupToggle()}
                                        disabled={isCardPopupOpen}
                                    >
                                        Editar contenido
                                    </p>
                                    <PopupFormat
                                        togglePopup={handleCardPopupToggle}
                                        isPopupOpen={isCardPopupOpen}
                                        modifyName={"Modificar tarjeta de contenido"}
                                        formPopup={
                                            <FormHomeCard
                                                selectedId={cardID} />}
                                        id={cardID}
                                    />
                                </>
                            )}
                        </label>
                        <hr></hr>
                        {loading ? (
                            <Loader />
                        ) : (
                            <p id="texto-resumen">
                                {cardContent}
                            </p>
                        )}
                    </div>
                    {cardImage}
                </div>
            )}
            {user && user.role === "TRUE" && (
                <div className='summary-edit-options'>
                    <p
                        onClick={() => isDeleteInteractionDisabled ? null : handleDeleteContentPopupToggle(cardID)}
                        disabled={isDeleteContentPopupOpen}
                    >
                        Eliminar Contenido
                    </p>
                    <PopupFormat
                        togglePopup={handleDeleteContentPopupToggle}
                        isPopupOpen={isDeleteContentPopupOpen}
                        formPopup={
                            <FormConfirmEspecialidad
                                selectedId={cardID}
                                handleClose={handleDeleteContentPopupToggle}
                            />
                        }
                    />
                </div>
            )}
        </>
    );
};
export default SpecialityFormat1;
