import { createContext, useContext, useEffect, useState } from "react";
import { updateSlogan, getSlogans } from "../api/slogan";

export const SloganContext = createContext();

export const useSlogan = () => {
    const context = useContext(SloganContext);
    if (!context) {
        throw new Error("useSlogan must be used within a SloganProvider");
    }
    return context;
};

export const SloganProvider = ({ children }) => {
    const [slogan, setSlogan] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    const getSlogansData = async () => {
        try {
            const res = await getSlogans();
            setSlogan(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    }; 

    const updateSloganData = async (id, slogan) => {
        try {
            const res = await updateSlogan(id, slogan);
            setSlogan((prevSlogan) =>
                prevSlogan.map((slogan) => (slogan._id === id ? res.data : slogan))
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        getSlogansData();
    }, []);
    return (
        <SloganContext.Provider
            value={{
                slogan,
                errors,
                loading,
                updateSloganData: updateSloganData,
                getSlogansData,
            }}
        >
            {children}
        </SloganContext.Provider>
    );
};