import React from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import "../../styles/styles.css";

const PopupPicture = ({ pictureID, togglePopup, isPopupOpen }) => {
    const { control, handleSubmit, formState: { errors } } = useForm();

    const handlePopup = () => {
        togglePopup();
    }

    return (
        <>
            {isPopupOpen && (
                <div onClick={togglePopup}>
                    <Popup                        
                        open={isPopupOpen}
                        closeOnDocumentClick={false}
                        onClose={handlePopup}
                    >
                        <div className='form-popup-full-pic'>
                            <div className='full-pic-container'>
                                <img src={pictureID} alt="proyectImage" />
                            </div>
                        </div>
                    </Popup>
                </div>
            )}
        </>
    );
}
export default PopupPicture;