import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import "../../styles/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const closeIcon = faXmark;

const PopupFormat = ({ togglePopup, isPopupOpen, modifyName, formPopup }) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        setIsFormSubmitted(true);
    }

    const handlePopup = () => {
        togglePopup();
        setIsFormSubmitted(false);
    }

    return (
        <>
            {isPopupOpen && (
                <div onClick={togglePopup}>
                    <Popup
                        open={isPopupOpen}
                        closeOnDocumentClick={false}
                        onClose={handlePopup}
                    >
                        <div className='form-popup'>
                            <div className='form-back-container'>
                                <button
                                    onClick={handlePopup}
                                    className='form-back-btn'
                                >
                                    <FontAwesomeIcon icon={closeIcon} />
                                </button>
                            </div>
                            <div className='form-popup-content'>
                                <div>
                                    <label htmlFor="options">{modifyName}</label>
                                </div>
                                <div className='form-container'>
                                    {formPopup}
                                </div>
                            </div>
                        </div>
                    </Popup>
                </div>
            )}
        </>
    );
}
export default PopupFormat;