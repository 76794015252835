import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useVision } from '../context/Vision.Context';

export const FormVision = ({ selectedId }) => {
    const { setVisions, visions, updateVision } = useVision();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const vision = visions.find((vision) => vision._id === selectedId);

    useEffect(() => {
        setValue('title', vision.title);
        setValue('description', vision.description);
    }, [setValue, vision.title, vision.description, vision._id]);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const onSubmit = async (data) => {
        try {
            if (selectedId && !formSubmitted) {
                setFormSubmitted(true);

                const formData = new FormData();
                formData.append('title', data.title);
                formData.append('description', data.description);

                const updatedVision = await updateVision(selectedId, formData);
                console.log(updatedVision);
                
                setVisions((prevVisions) =>
                    prevVisions.map((v) => (v._id === selectedId ? updatedVision : v))
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='FormProfile'>
                <div className='forms-container'>
                    <input
                        type='text'
                        id='title'
                        placeholder="Titulo de la vision"
                        {...register('title', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                        defaultValue={vision.title}
                    />
                    {errors.title && (
                        <span className='err-msg'>{errors.title.message}</span>
                    )}
                    <textarea
                        type='text'
                        id='description'
                        placeholder="Descripcion de la vision"
                        {...register('description', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms-text-area-vision'
                        defaultValue={vision.description}
                    />
                    {errors.description && (
                        <span className='err-msg'>{errors.description.message}</span>
                    )}
                    <button type="submit" className="button-box" disabled={formSubmitted}>
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormVision;  