import { createContext, useContext, useEffect, useState } from "react";
import { loginRequest, verifyTokenRequest, updateUser as updateUserRequest } from "../api/auth";
import Cookies from 'js-cookie';
export const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [setAdmin] = useState(false);
    const [userData] = useState(false);

    const signIn = async (user) => {
        try {
            const res = await loginRequest(user);
            setIsAuthenticated(true);
            setUser(res.data);
            if (userData.role === 'TRUE') {
                setAdmin(true);
            }
        } catch (err) {
            if (Array.isArray(err.response.data)) {
                return setErrors(err.response.data);
            }
            setErrors([err.response.data.message]);
        }
    }

    const updateUser = async (userId, userData) => {
        try {
            const res = await updateUserRequest(userId, userData);
            setUser(res.data);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const logout = () => {
        Cookies.remove('token');
        setIsAuthenticated(false);
        setUser(null);
    }

    useEffect(() => {
        async function checkLogin() {
            const cookies = Cookies.get();
            if (!cookies.token) {
                setIsAuthenticated(false);
                setLoading(false);
                setUser(null);
            } else {
                try {
                    const res = await verifyTokenRequest(cookies.token);
                    if (!res.data) {
                        setIsAuthenticated(false);
                        setLoading(false);
                        setUser(null);
                    } else {
                        setIsAuthenticated(true);
                        setUser(res.data);
                        setLoading(false);
                    }
                } catch (err) {
                    setIsAuthenticated(false);
                    setUser(null);
                    setLoading(false);
                }
            }
        }
        checkLogin();
    }, []);



    return (
        <AuthContext.Provider
            value={{
                signIn,
                logout,
                user,
                updateUser,
                loading,
                isAuthenticated,
                errors
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}