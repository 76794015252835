import { createContext, useContext, useEffect, useState } from "react";
import { getVision, getVisions, updateVision, deleteVision } from "../api/vision";

export const VisionContext = createContext();

export const useVision = () => {
    const context = useContext(VisionContext);
    if (!context) {
        throw new Error("useVision must be used within a VisionProvider");
    }
    return context;
};

export const VisionProvider = ({ children }) => {
    const [visions, setVisions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    const getVisionsData = async () => {
        try {
            const res = await getVisions();
            setVisions(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const getVisionById = async (id) => {
        try {
            const res = await getVision(id);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const updateVisionData = async (id, vision) => {
        try {
            const res = await updateVision(id, vision);
            setVisions((prevVisions) =>
                prevVisions.map((vision) => (vision._id === id ? res.data : vision))
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteVisionData = async (id) => {
        try {
            const res = await deleteVision(id);
            setVisions((prevVisions) =>
                prevVisions.filter((vision) => vision._id !== id)
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        async function checkVisions() {
            try {
                await getVisionsData();
            } catch (err) {
                setErrors([err.message]);
            }
        }
        checkVisions();
    }, []);

    return (
        <VisionContext.Provider
            value={{
                visions,
                setVisions,
                errors,
                setErrors,
                loading,
                setLoading,                
                getVisions: getVisionsData,
                getVision: getVisionById,
                updateVision: updateVisionData,
                deleteVision: deleteVisionData,
            }}
        >
            {children}
        </VisionContext.Provider>
    );
};