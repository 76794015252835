import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

let TopButton;

export function topFunction() {
  document.body.scrollTo({ top: 0, behavior: 'smooth' }); // For Safari
  document.documentElement.scrollTo({ top: 0, behavior: 'smooth' }); // For Chrome, Firefox, IE and Opera
}

class GoTop extends React.Component {
  componentDidMount() {
    TopButton = document.getElementById("topButton");
    window.addEventListener('scroll', this.scrollFunction);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollFunction);
  }
  scrollFunction() {
    if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
      TopButton.style.display = "block";
    } else {
      TopButton.style.display = "none";
    }
  }
  render() {
    return (
      <div>
        <button onClick={topFunction} class="go-top-btn" id="topButton" title='Subir a inicio'>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      </div>
    );
  }
}
export default GoTop;