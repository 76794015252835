import React from "react";
import { Route, Routes } from "react-router-dom";
import PortfolioPage from "./portafolioPage";
import NotFoundPage from "./notFoundPage";
import PortofolioProyect from "./portafolioPage.Proyects";

const PortafolioRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<PortfolioPage />} />
                <Route path=":proyectoID?" element={<PortofolioProyect />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </>
    );
};
export default PortafolioRoutes;
