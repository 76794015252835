import React from 'react';
import { useMapInfoCard } from '../context/MapLocationsCardInfo.Context';
import { useMapLocation } from '../context/MapLocations.Context';

const FormConfirm = ({ selectedId, handleClose, proyectID }) => {
    const { mapInfoCards, deleteMapInfoCardData } = useMapInfoCard();
    const { updateMapLocationData } = useMapLocation();
    
    const confirmDelete = async (cardID) => {
        try {
            await (cardID);
            const updatedMapInfoCards = mapInfoCards.filter((card) => card._id !== cardID);
            if (proyectID) {
                await updateMapLocationData(proyectID, {
                    mapInfoCards: updatedMapInfoCards,
                });
            };
            await (selectedId);
            if (selectedId) {
                await deleteMapInfoCardData(selectedId);
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
        }
    };
    return (
        <div className='FormProfile'>
            <div className='forms-container'>
                <p>¿Estás seguro de que quieres eliminar este contenido?</p>
                <div className='form-btns'>
                    <button
                        className='btn-forms'
                        onClick={() => confirmDelete(selectedId)}
                    >
                        Eliminar
                    </button>
                    <button
                        className='btn-forms'
                        onClick={handleClose}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};
export default FormConfirm;