import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useMapLocation } from '../../context/MapLocations.Context';
import MapData from './mapData';
import { Fade } from "react-awesome-reveal";
import Loader from "../loader";
const chileCenter = [-33.45, -70.65];

const markerIcon = L.icon({
    iconUrl: 'https://res.cloudinary.com/dsmubyyv9/image/upload/v1700514212/LOGOS/qrtbrjzvgtxdomtr2izs.png',
    iconSize: [30, 30],
});

const Map = () => {
    const { mapLocations, loading } = useMapLocation();
    const [selectedLocation, setSelectedLocation] = useState(null);

    const handleMarkerClick = (id) => {
        setSelectedLocation(id);
    };

    return (
        <>

            <div className="map-container">
                <div className='map-container-title'>
                    <h1>Proyectos a lo largo de todo Chile</h1>
                </div>
                <div className='map-container-content'>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Fade
                            cascade
                            triggerOnce={true}
                            direction='down'
                        >
                            <MapContainer center={chileCenter} zoom={8} className="map-format">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {mapLocations.map((mapLocation) => {
                                    const coords = mapLocation.mapLocationCoords.split(',').map(coord => parseFloat(coord.trim()));

                                    return (
                                        <Marker
                                            key={mapLocation._id}
                                            position={coords}
                                            icon={markerIcon}
                                            eventHandlers={{ click: () => handleMarkerClick(mapLocation._id) }}
                                        />
                                    );
                                })}
                            </MapContainer>
                            <MapData selectedID={selectedLocation} />
                        </Fade>
                    )}
                </div>
            </div>
        </>
    );
};

export default Map;

