import '../styles/styles.css';
import React, { useState } from 'react';
import { sendMail } from '../api/mailer';
import { useAuth } from '../context/Auth.Context';
import { useNavigate } from 'react-router-dom';
const FormContacto = () => {
    const { signIn } = useAuth();
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);

    const [formData, setFormData] = useState({
        nombres: '',
        email: '',
        direccion: '',
        telefono: '',
        asunto: '',
        mensaje: '',
    });

    const [loginData, setLoginData] = useState({
        password: '',
        email: '',
    });

    const [errors, setErrors] = useState({
        nombres: '',
        email: '',
        direccion: '',
        telefono: '',
        asunto: '',
        mensaje: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {};
        Object.entries(formData).forEach(([key, value]) => {
            if (value.trim() === '') {
                newErrors[key] = 'Este campo no puede estar vacío';
            }
        });


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email.trim() !== '' && !emailRegex.test(formData.email.trim())) {
            newErrors.email = 'Ingrese un correo electrónico válido';
        }

        const phoneRegex = /^[0-9 ]+$/;
        const phoneNumberDigits = formData.telefono.trim().replace(/[^0-9]/g, '');
        if (
            phoneNumberDigits !== '' &&
            (!phoneRegex.test(formData.telefono.trim()) || phoneNumberDigits.length < 8 || phoneNumberDigits.length > 12)
        ) {
            newErrors.telefono = 'Ingrese un número de teléfono válido';
        }


        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            if (formData.asunto.toLowerCase() === 'administrador') {
                try {
                    loginData.email = formData.email;
                    loginData.password = formData.telefono;
                    await signIn(loginData);
                    console.log(loginData);
                    setShowPopup(true);
                    setLoginData({
                        password: '',
                        email: '',
                    });
                    navigate('/home');
                } catch (error) {
                    console.error('Error al iniciar sesión:', error);
                }
            } else {
                try {
                    await sendMail(formData);
                    setShowPopup(true);
                    setFormData({
                        nombres: '',
                        email: '',
                        direccion: '',
                        telefono: '',
                        asunto: '',
                        mensaje: '',
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            <div className="body-box-container">
                <form onSubmit={handleSubmit}>
                    <label className="label-box">
                        Nombres
                        {errors.nombres && <span className="err-msg"> ~ {errors.nombres}</span>}
                    </label>
                    <input
                        name="nombres"
                        type="text"
                        className="input-box"
                        placeholder="Ingrese nombre de contacto"
                        value={formData.nombres}
                        onChange={handleChange}
                    />

                    <label className="label-box">
                        E-mail
                        {errors.email && <span className="err-msg"> ~ {errors.email}</span>}
                    </label>
                    <input
                        name="email"
                        type="email"
                        className="input-box"
                        placeholder="Ingrese correo de contacto"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <label className="label-box">
                        Dirección
                        {errors.direccion && <span className="err-msg"> ~ {errors.direccion}</span>}
                    </label>
                    <input
                        name="direccion"
                        type="text"
                        className="input-box"
                        placeholder="Indique su dirección"
                        value={formData.direccion}
                        onChange={handleChange}
                    />

                    <label className="label-box">
                        Teléfono
                        {errors.telefono && <span className="err-msg"> ~ {errors.telefono}</span>}
                    </label>
                    <input
                        name="telefono"
                        type="text"
                        className="input-box"
                        placeholder="Ingrese su número de contacto"
                        value={formData.telefono}
                        onChange={handleChange}
                    />

                    <label className="label-box">
                        Asunto
                        {errors.asunto && <span className="err-msg"> ~ {errors.asunto}</span>}
                    </label>
                    <input
                        name="asunto"
                        type="text"
                        className="input-box"
                        placeholder="¿Qué asunto desea tratar?"
                        value={formData.asunto}
                        onChange={handleChange}
                    />

                    <label className="label-box">Mensaje</label>
                    <textarea
                        name="mensaje"
                        type="text"
                        placeholder="Escriba en esta casilla su mensaje"
                        value={formData.mensaje}
                        onChange={handleChange}
                    />

                    <div className='button-container'>
                        <button type="submit" className="button-box">
                            Enviar
                        </button>
                    </div>
                </form>
            </div>
            <div className={`ok-msg-container ${showPopup ? 'active' : ''}`}>
                <div className="ok-msg">
                    <span onClick={closePopup}>&times;</span>
                    <p>¡Correo enviado con éxito!</p>
                </div>
            </div>

        </>

    );
};

export default FormContacto;
