import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const NotFoundPage = () => {
    const currentURL = window.location.href;
    return (
        <>
            <div className="App">
                <main className="page-content">
                    <div className="not-found-container">
                        <div className="nf-left">
                            <img className="text-logo" alt="logo"
                                src="https://res.cloudinary.com/dsmubyyv9/image/upload/v1700514211/LOGOS/fgkt3n3pdmgneosik3de.png" />
                            <hr></hr>
                            <p>La página {currentURL} , no se encuentra dentro de la plataforma.</p>
                            <div className='project-go-back'>
                                <FontAwesomeIcon className='fa-angle-left' icon={faAngleLeft} />
                                <a href='/home' rel='noreferrer noopener'>Volver al inicio</a>
                            </div>
                        </div>
                        <div className="nf-right">
                            <img className="logo-big" alt="logo" src="https://res.cloudinary.com/dsmubyyv9/image/upload/v1700514212/LOGOS/qrtbrjzvgtxdomtr2izs.png" />
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};
export default NotFoundPage;