import "../../../styles/styles.css";
import React, { useState } from 'react';
import ProyectCardFormat from "./proyectCardFormat";
import ProyectCardFormatPinned from "./proyectCardFormatPinned";
import { useMapLocation } from "../../../context/MapLocations.Context";
import Loader from "../../loader";
import { handlePopupToggle } from "../../../utils/handlePopup";
import { useAuth } from "../../../context/Auth.Context";
import PopupPicture from "../../popups/popupFullPicture";
import { Fade } from "react-awesome-reveal";
import PopupFormat from "../../popups/popupFormat";
import FormNewProyect from "../../../forms/proyectsPageForms/formNewProyecto";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { topFunction } from '../../../utils/goTop';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const ProyectsData = ({ selectedID }) => {
    const { mapLocations, loading } = useMapLocation();
    const { user } = useAuth();

    const [isInteractionDisabled, setInteractionDisabled] = useState({});
    const [isPicturePopupsOpen, setIsPicturePopupsOpen] = useState({});

    const [isNewProyectoPopupOpen, setIsNewProyectoPopupOpen] = useState(false);
    const [isNewProyectoInteractionDisabled, setNewProyectoInteractionDisabled] = useState(false);

    const handleNewProyectoPopupToggle = () => {
        handlePopupToggle(setNewProyectoInteractionDisabled, setIsNewProyectoPopupOpen, isNewProyectoInteractionDisabled, isNewProyectoPopupOpen);
    }

    const handlePicturePopupToggle = (id) => {
        handlePopupToggle(
            () => setInteractionDisabled((prev) => ({ ...prev, [id]: !prev[id] })),
            (value) => setIsPicturePopupsOpen((prev) => ({ ...prev, [id]: value })),
            isInteractionDisabled[id],
            isPicturePopupsOpen[id]
        );
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <h1>Proyectos destacados</h1>
                    <div className="projects-pinned">
                        {mapLocations.map((location) => (
                            location.mapLocationFav === '1' ? (
                                <div className="proyectCard" key={location._id}>
                                    <Fade
                                        cascade
                                        triggerOnce={true}
                                        direction='left'
                                        className="home-card-data"
                                        duration={1000}
                                    >
                                        <ProyectCardFormatPinned
                                            mapLocationInfoList={location.mapInfoCards}
                                            mapLocationTitle={location.mapLocationTitle}
                                            mapLocationDescription={location.mapLocationDescription}
                                            mapLocationImage={
                                                <div onClick={() => handlePicturePopupToggle(location._id)}>
                                                    <PopupPicture
                                                        pictureID={location.mapLocationImage}
                                                        togglePopup={() => handlePicturePopupToggle(location._id)}
                                                        isPopupOpen={isPicturePopupsOpen[location._id]}
                                                    />
                                                    <img className="project-img" src={location.mapLocationImage} alt="proyectImage" />
                                                </div>
                                            }
                                            mapLocationID={location._id}
                                        />
                                    </Fade>
                                </div>
                            ) : null
                        ))}
                    </div>

                    <h1>Ultimos proyectos</h1>
                    <div className="last-projects">
                        {mapLocations
                            .filter((location) => location.mapLocationFav !== '1')
                            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                            .slice(0, 3)
                            .map((location) => (
                                <div className="projectCard" key={location._id}>
                                    <Fade
                                        cascade
                                        triggerOnce={true}
                                        damping={0.1}
                                        direction="left"
                                        className="home-card-data"
                                        duration={1000}
                                    >
                                        <ProyectCardFormat
                                            mapLocationInfoList={location.mapInfoCards}
                                            mapLocationTitle={location.mapLocationTitle}
                                            mapLocationDescription={location.mapLocationDescription}
                                            mapLocationImage={
                                                <div onClick={() => handlePicturePopupToggle(location._id)}>
                                                    <PopupPicture
                                                        pictureID={location.mapLocationImage}
                                                        togglePopup={() => handlePicturePopupToggle(location._id)}
                                                        isPopupOpen={isPicturePopupsOpen[location._id]}
                                                    />
                                                    <img className="project-img" src={location.mapLocationImage} alt="proyectImage" />
                                                </div>
                                            }
                                            mapLocationID={location._id}
                                        />
                                    </Fade>
                                </div>
                            ))}
                    </div>

                    {user && user.role === "TRUE" && (
                        <>
                            <div className="vision-edit-container">
                                <p
                                    className="vision-edit-project"
                                    onClick={() => isNewProyectoInteractionDisabled ? null : handleNewProyectoPopupToggle()}
                                    disable={isNewProyectoPopupOpen}
                                >
                                    Agregar nuevo proyecto
                                </p>
                                <PopupFormat
                                    togglePopup={handleNewProyectoPopupToggle}
                                    isPopupOpen={isNewProyectoPopupOpen}
                                    modifyName={"Crear nuevo proyecto"}
                                    formPopup={<FormNewProyect />}
                                />
                            </div>
                        </>
                    )}
                    <h1>Mas proyectos</h1>
                    <div className='slide-container'>
                        <Slide slidesToShow={3}>
                            {mapLocations.map((location) => (
                                location ? (
                                    <div key={location._id}>
                                        <div onClick={() => handlePicturePopupToggle(location._id)}>
                                            <PopupPicture
                                                pictureID={location.mapLocationImage}
                                                togglePopup={() => handlePicturePopupToggle(location._id)}
                                                isPopupOpen={isPicturePopupsOpen[location._id]}
                                            />
                                            <img className="slide-img" src={location.mapLocationImage} alt="proyectImage" />
                                        </div>
                                        <Link onClick={topFunction} to={`/portafolio/${location._id}`} className="project-see-more" >
                                            Ver mas
                                            <FontAwesomeIcon className='fa-angle-right' icon={faAngleRight} />
                                        </Link>
                                    </div>
                                ) : null
                            ))}
                        </Slide>
                    </div >
                </>
            )}
        </>
    );
};

export default ProyectsData;
