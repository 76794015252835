import React, { useState } from 'react';
import PopupFormat from "../../src/components/popups/popupFormat";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import FormContacto from "../components/contactForm.js";
import { useAuth } from "../context/Auth.Context";
import { useContact } from "../context/Contact.Context";
import FormChangeMail from '../forms/contactForms/formChangeMail.js';
import FormChangeNumber from '../forms/contactForms/formChangeNumber.js';
import { handlePopupToggle } from '../utils/handlePopup.js';

const Contacto = () => {
    const { user } = useAuth();
    const { contacts, loading } = useContact();
    const [isInteractionDisabled, setInteractionDisabled] = useState(false);
    const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
    const [isNumberPopupOpen, setIsNumberPopupOpen] = useState(false);

    const handleEmailPopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsEmailPopupOpen, isInteractionDisabled, isEmailPopupOpen);
    };


    const handleNumberPopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsNumberPopupOpen, isInteractionDisabled, isNumberPopupOpen);
    };

    return (
        <>
            <div className='App'>
                <Navbar />
                <div className="background-img"></div>
                <div className="contact-box">
                    <div className='contact-content'>
                        <label className="label-box-container" htmlFor="contact-info">
                            CONTÁCTANOS
                        </label>
                        <hr />
                        <div className='edit-contact-container'>
                            {user && user.role === "TRUE" && (
                                <>
                                    <p
                                        className="contact-info-edit"
                                        onClick={() => handleEmailPopupToggle()}
                                        disabled={isEmailPopupOpen}
                                    >
                                        Editar correo de contacto
                                    </p>
                                    <PopupFormat
                                        togglePopup={handleEmailPopupToggle}
                                        isPopupOpen={isEmailPopupOpen}
                                        modifyName={"Modificar correo de contacto"}
                                        formPopup={<FormChangeMail />}
                                    />
                                </>
                            )}
                            {loading ? (
                                <p className="contact-info">Cargando...</p>
                            ) : (
                                <p className="contact-info">{contacts.length > 0 ? contacts[0].contactEmail : 'Sin datos'}</p>
                            )}
                            {user && user.role === "TRUE" && (
                                <>
                                    <p
                                        className="contact-info-edit"
                                        onClick={() => handleNumberPopupToggle()}
                                        disabled={isNumberPopupOpen}
                                    >
                                        Editar número de contacto
                                    </p>
                                    <PopupFormat
                                        togglePopup={handleNumberPopupToggle}
                                        isPopupOpen={isNumberPopupOpen}
                                        modifyName={"Modificar número de contacto"}
                                        formPopup={<FormChangeNumber />}
                                    />
                                </>
                            )}
                            {loading ? (
                                <p className="contact-info">Cargando...</p>
                            ) : (
                                <p className="contact-info" id="number">{contacts.length > 0 ? contacts[0].contactNumber : 'Sin datos'}</p>
                            )}
                        </div>
                        <FormContacto />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Contacto;
