import "../../styles/styles.css";
import React, { useState, useEffect } from 'react';
import { useMapLocation } from "../../context/MapLocations.Context";
import Loader from "../loader";
import { handlePopupToggle } from "../../utils/handlePopup";
import { useAuth } from "../../context/Auth.Context";
import PopupPicture from "../popups/popupFullPicture";
import ProyectCardFormat from "../proyectCards/proyectCardsPage/proyectCardFormat";
const MapData = ({ selectedID }) => {

    const { mapLocations, loading } = useMapLocation();
    const { user } = useAuth();

    const [isInteractionDisabled, setInteractionDisabled] = useState(false);
    const [isProyectPopupOpen, setIsProyectPopupOpen] = useState(false);
    const [isPicturePopupOpen, setIsPicturePopupOpen] = useState(false);
    const [fadeClass, setFadeClass] = useState('');

    useEffect(() => {
        setFadeClass('homeMap-fadeIn');
        const timeout = setTimeout(() => setFadeClass(''), 300);
        return () => clearTimeout(timeout);
    }, [selectedID]);

    const mapLocation = selectedID
        ? mapLocations.find(location => location._id === selectedID)
        : mapLocations[0];

    const handleProyectPopupToggle = (proyectID) => {
        handlePopupToggle(setInteractionDisabled, setIsProyectPopupOpen, isInteractionDisabled, isProyectPopupOpen);
    };

    const handlePicturePopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsPicturePopupOpen, isInteractionDisabled, isPicturePopupOpen);
    }


    return (
        <>
            <div className={`home-map ${fadeClass}`}>
                {loading ? (
                    <p><Loader /></p>
                ) : (
                    <ProyectCardFormat
                        mapLocationTitle={mapLocation.mapLocationTitle}
                        mapLocationDescription={mapLocation.mapLocationDescription}
                        mapLocationImage={
                            <div onClick={() => handlePicturePopupToggle()}>
                                <PopupPicture
                                    pictureID={mapLocation.mapLocationImage}
                                    togglePopup={handlePicturePopupToggle}
                                    isPopupOpen={isPicturePopupOpen}
                                />
                                <img className="project-img" src={mapLocation.mapLocationImage} alt="proyectImage" />
                            </div>
                        }
			mapLocationFav={mapLocation.mapLocationFav}
                        mapLocationID={mapLocation._id}
                    />
                )}
            </div>
        </>
    );
};

export default MapData;
