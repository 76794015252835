import React from 'react';
import { useMapLocation } from '../../context/MapLocations.Context';
import { useMapInfoCard } from '../../context/MapLocationsCardInfo.Context';

const FormConfirmDeleteProyect = ({ selectedId, handleClose, proyectInfoList }) => {
    const { deleteMapLocationData } = useMapLocation();
    const { deleteMapInfoCardData } = useMapInfoCard();
    const confirmDelete = async () => {
        try {
            await (selectedId);
            if (selectedId) {
                console.log("selectedId:", selectedId);
                await deleteMapLocationData(selectedId);
                for (const info of proyectInfoList) {
                    await deleteMapInfoCardData(info);
                }

            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
        }
    };
    return (
        <div className='FormProfile'>
            <div className='forms-container'>
                <p>¿Estás seguro de que quieres eliminar este contenido?</p>
                <div className='form-btns'>
                    <button
                        className='btn-forms'
                        onClick={() => confirmDelete(selectedId)}
                    >
                        Eliminar
                    </button>
                    <button
                        className='btn-forms'
                        onClick={handleClose}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};
export default FormConfirmDeleteProyect;