import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSpecialityCard } from '../../context/SpecialityCards.Context';

export const FormSpeciality = () => {
    const { createSpecialityCard } = useSpecialityCard();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [selectedFile, setSelectedFile] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        console.log(file);
    };

    const onSubmit = async (data) => {
        try {
            if (!formSubmitted) {
                setFormSubmitted(true);

                const formData = new FormData();
                formData.append('cardTitle', data.cardTitle);
                formData.append('cardContent', data.cardContent);
                formData.append('cardFormat', data.cardFormat);
                formData.append('cardImage', selectedFile);

                const newCard = await createSpecialityCard(formData);
                console.log(newCard);
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className='FormProfile'>
                <div className='forms-container'>
                    <input
                        type='text'
                        id='cardTitle'
                        placeholder="Titulo de la especialidad"
                        {...register('cardTitle', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                    />
                    {errors.cardTitle && <span className='input-forms-err'>{errors.cardTitle.message}</span>}

                    <textarea
                        type='text'
                        id='cardContent'
                        placeholder="Descripción de la especialidad"
                        {...register('cardContent', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms-text-area'
                    />

                    {errors.cardContent && <span className='input-forms-err'>{errors.cardContent.message}</span>}

                    <div className='input-forms-img-container'>
                        <label className='input-forms-img-btn'>
                            Cargar imagen
                            <input
                                type='file'
                                id='cardImage'
                                {...register('cardImage', {
                                    required: 'Este campo es requerido'
                                })}
                                className='input-forms-img'
                                onChange={handleFileChange}
                            />
                        </label>
                        {selectedFile && (
                            <span className='input-forms-img-file'>
                                {selectedFile.name}
                            </span>
                        )}
                    </div>
                    <label>Tipo de formato</label>
                    <div className='form-options'>
                        <label title='Texto en izquierda, imagen en derecha'>
                            <input
                                type='radio'
                                {...register('cardFormat',)}
                                value='1'
                            />
                            Formato 1
                        </label>
                        <label title='Texto en derecha, imagen en izquierda'>
                            <input
                                type='radio'
                                {...register('cardFormat',)}
                                value='2'
                            />
                            Formato 2
                        </label>
                    </div>
                    {errors.cardFormat && (
                        <span className='err-msg'>{errors.cardFormat.message}</span>
                    )}

                    <button type="submit" className="button-box" disabled={formSubmitted}>
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormSpeciality;
