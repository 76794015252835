import React from 'react';
import { useSpecialityCard } from '../../context/SpecialityCards.Context';
const FormConfirmEspecialidad = ({ selectedId, handleClose }) => {
    const { deleteSpecialityCard } = useSpecialityCard();

    const confirmDelete = async () => {
        try {            
            await (selectedId);
            if (selectedId) {
                console.log("selectedId:", selectedId);
                await deleteSpecialityCard(selectedId);
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
        }
    };
    return (
        <div className='FormProfile'>
            <div className='forms-container'>
                <p>¿Estás seguro de que quieres eliminar este contenido?</p>
                <div className='form-btns'>
                    <button
                        className='btn-forms'
                        onClick={() => confirmDelete(selectedId)}
                    >
                        Eliminar
                    </button>
                    <button
                        className='btn-forms'
                        onClick={handleClose}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};
export default FormConfirmEspecialidad;