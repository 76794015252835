import { Grid } from 'react-loader-spinner'

function Loader() {
  return (
    <div>
      <Grid
        wrapperClass="loader"
        color="#00549f" />
    </div>
  );
}
function LoaderSlogan() {
  return (
    <div>
      <Grid
        wrapperClass="loader"
        color="#ffffff" />
    </div>
  );
}
export default Loader;
export { LoaderSlogan };
