import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMapLocation } from '../../context/MapLocations.Context';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

export const FormEditProyects = ({ selectedId }) => {
    const { mapLocations, updateMapLocationData } = useMapLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const mapLocation = mapLocations.find((proyect) => proyect._id === selectedId);

    useEffect(() => {
        setValue('mapCoords', mapLocation.mapLocationCoords);
        setValue('mapLocationTitle', mapLocation.mapLocationTitle);
        setValue('mapLocationDescription', mapLocation.mapLocationDescription);
        setValue('mapLocationImage', mapLocation.mapLocationImage);
	setValue('mapLocationFav',mapLocation.mapLocationFav);
    }, [setValue, mapLocation.mapLocationTitle, mapLocation.mapLocationDescription, mapLocation._id, mapLocation.mapLocationImage, mapLocation.mapLocationCoords, mapLocation.mapLocationFav]);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    //carga de imagen
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    //busqueda de ubicacion
    const handleSearchChange = async (input) => {
        try {
            const provider = new OpenStreetMapProvider();
            const results = await provider.search({ query: input });
            setSearchResults(results);
        } catch (error) {
            console.error('Error searching location:', error);
        }
    };

    //seteo de coordenadas
    const handleSelectLocation = (result) => {
        setValue('mapCoords', `${result.y}, ${result.x}`);
        setSearchResults([]);
    };

    const onSubmit = async (data) => {
        try {
            if (selectedId && !formSubmitted) {
                setFormSubmitted(true);
                const formData = new FormData();
                formData.append('mapLocationTitle', data.mapLocationTitle);
                formData.append('mapLocationDescription', data.mapLocationDescription);
                formData.append('mapLocationCoords', data.mapCoords);
                formData.append('mapLocationImage', selectedFile);
		formData.append('mapLocationFav', data.mapLocationFav)
                const updatedProyect = await updateMapLocationData(selectedId, formData);
                console.log(updatedProyect);
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='FormProfile'>
                <div className='forms-container'>
                    <input
                        type='text'
                        id='mapCoords'
                        placeholder="Coordenadas del proyecto"
                        {...register('mapCoords', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                        style={{ display: 'none' }}
                        defaultValue={mapLocation.mapLocationCoords}
                    />
                    {errors.mapLocationCoords && (
                        <span className='err-msg'>{errors.mapLocationCoords.message}</span>
                    )}
                    <input
                        type='text'
                        id='mapTitle'
                        placeholder="Titulo del proyecto"
                        {...register('mapLocationTitle', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                        defaultValue={mapLocation.mapLocationTitle}
                    />
                    {errors.mapLocationCoords && (
                        <span className='err-msg'>{errors.mapLocationCoords.message}</span>
                    )}
                    <input
                        type='text'
                        id='searchLocation'
                        placeholder="Buscar ubicación"
                        onChange={(e) => handleSearchChange(e.target.value)}
                        className='search-results-input'
                    />
                    {searchResults.length > 0 && (
                        <div className='search-results'>
                            {searchResults.length > 0 && (
                                searchResults.map((result, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSelectLocation(result)}
                                    >
                                        {result.label}
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                    <textarea
                        type='text'
                        id='mapLocationDescription'
                        placeholder="Descripcion del proyecto"
                        {...register('mapLocationDescription',)}
                        className='input-forms-text-area-vision'
                        defaultValue={mapLocation.mapLocationDescription}
                    />
                    {errors.mapLocationDescription && (
                        <span className='err-msg'>{errors.mapLocationDescription.message}</span>
                    )}
                    <div className='input-forms-img-container'>
                        <label className='input-forms-img-btn'>
                            Cargar imagen
                            <input
                                type='file'
                                id='mapLocationImage'
                                {...register('mapLocationImage')}
                                className='input-forms-img'
                                onChange={handleFileChange}
                            />
                        </label>
                        {selectedFile && (
                            <span className='input-forms-img-file'>
                                {selectedFile.name}
                            </span>
                        )}
                    </div>
                    <label className='pinned-proyect'>Proyecto fijado</label>
                    <div className='form-options'>
                        <label className='switch' title='Permanecer fijado'>
                            <input
                                type='checkbox'
                                {...register('mapLocationFav',)}
                                value='1'
                            />
                            <span className='slider'></span>
                        </label>
                    </div>
                    <button type="submit" className="button-box" disabled={formSubmitted}>
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormEditProyects;
