import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useContact } from '../../context/Contact.Context';

export const FormChangeMail = ({ togglePopup }) => {
    const { contacts, updateContact } = useContact();
    const contact = contacts[0];
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        setValue('email', contact.contactEmail);
        setSelectedId(contact._id);
    }, [setValue, contact.contactEmail, contact._id]);

    const onSubmit = async (data) => {
        try {
            if (selectedId) {
                const updatedContact = await updateContact(selectedId, { contactEmail: data.email });
                console.log(updatedContact);
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
            togglePopup();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='FormProfile'>
                <div className='forms-container'>
                    <input
                        type='text'
                        id='Email'
                        placeholder="Email"
                        {...register('email', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                    />
                    {errors.email && <span className='input-forms-err'>{errors.email.message}</span>}

                    <button type="submit" className="button-box">
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormChangeMail;