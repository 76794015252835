import React from 'react';
import { useAuth } from '../context/Auth.Context';

const FormConfirmClose = ({ handleClose }) => {
    const { logout } = useAuth();
    return (
        <div className='FormProfile'>
            <div className='forms-container'>
                <p>¿Quieres salir de la vista de administrador?</p>
                <div className='form-btns'>
                    <button
                        className='btn-forms'
                        onClick={() => {
                            logout();
                            handleClose();
                        }}
                    >
                        Si
                    </button>
                    <button
                        className='btn-forms'
                        onClick={handleClose}
                    >
                        No
                    </button>
                </div>
            </div>
        </div >
    );
};
export default FormConfirmClose;