import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMapInfoCard } from '../../context/MapLocationsCardInfo.Context';

const FormEditProyectInfo = ({ selectedId }) => {
    const { mapInfoCards, updateMapInfoCardData } = useMapInfoCard();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const mapInfoCard = mapInfoCards.find((card) => card._id === selectedId);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const onSubmit = async (data) => {
        try {
            if (selectedId && !formSubmitted) {
                setFormSubmitted(true);
                const formData = new FormData();
                formData.append('cardTitle', data.cardTitle);
                formData.append('cardDescription', data.cardDescription);
                formData.append('cardImage', selectedFile);
                formData.append('cardFormat', data.cardFormat);
                const updatedCard = await updateMapInfoCardData(selectedId, formData);                
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='FormProfile'>
                <div className='forms-container'>
                    <label htmlFor='cardTitle'>Titulo del contenido</label>
                    <input
                        type='text'
                        id='cardTitle'
                        placeholder="Título del proyecto"
                        {...register('cardTitle',)}
                        className='input-forms'
                        defaultValue={mapInfoCard?.cardTitle || 'Titulo del contenido'}
                    />
                    {errors.cardTitle && (
                        <span className='err-msg'>{errors.cardTitle.message}</span>
                    )}

                    <label htmlFor='cardDescription'>Texto del contenido</label>
                    <textarea
                        type='text'
                        id='cardDescription'
                        placeholder="Descripción del proyecto"
                        {...register('cardDescription',)}
                        className='input-forms-project-page'
                        defaultValue={mapInfoCard?.cardDescription || 'Descripcion del contenido'}
                    />
                    {errors.cardDescription && (
                        <span className='err-msg'>{errors.cardDescription.message}</span>
                    )}
                    <div className='input-forms-img-container'>
                        <label className='input-forms-img-btn'>
                            Cargar imagen
                            <input
                                type='file'
                                id='cardImage'
                                placeholder="Imagen del proyecto"
                                {...register('cardImage',)}
                                className='input-forms-img'
                                onChange={handleFileChange}
                            />
                        </label>
                        {selectedFile && (
                            <span className='input-forms-img-file'>
                                {selectedFile.name}
                            </span>
                        )}
                    </div>
                    <label>Tipo de formato</label>
                    <div className='form-options'>
                        <label title='Solo texto'>
                            <input
                                type='radio'
                                {...register('cardFormat',)}
                                value='1'
                                defaultChecked={mapInfoCard?.cardFormat === '1'}
                            />
                            Formato 1
                        </label>
                        <label title='Solo imagen en medio'>
                            <input
                                type='radio'
                                {...register('cardFormat',)}
                                value='2'
                                defaultChecked={mapInfoCard?.cardFormat === '2'}
                            />
                            Formato 2
                        </label>
                        <label title='Texto a la derecha, imagen a la izquierda'>
                            <input
                                type='radio'
                                {...register('cardFormat',)}
                                value='3'
                                defaultChecked={mapInfoCard?.cardFormat === '3'}
                            />
                            Formato 3
                        </label>
                        <label title='Texto a la izquierda, imagen a la derecha' >
                            <input
                                type='radio'
                                {...register('cardFormat',)}
                                value='4'
                                defaultChecked={mapInfoCard?.cardFormat === '4'}
                            />
                            Formato 4
                        </label>
                    </div>
                    {errors.cardFormat && (
                        <span className='err-msg'>{errors.cardFormat.message}</span>
                    )}

                    <button type='submit' className='buttonForm'>Guardar cambios</button>
                </div>
            </div>
        </form>
    );
};

export default FormEditProyectInfo;
