import React from "react";
import Navbar from "../components/navbar";
import Footer from '../components/footer';
import SpecialitesData from "../components/specialitiesCards/SpecialitiesData";
import ProyectsData from "../components/proyectCards/proyectCardsPage/proyectCardData";
import GotTop from "../utils/goTop";

const PortfolioPage = () => {
    return (
        <>
            <div className="App">
                <Navbar />
                <GotTop />
                <main className="page-content">
                    <div className="projects-title">
                        <ProyectsData />
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}
export default PortfolioPage;
