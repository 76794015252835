import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMapLocation } from '../../context/MapLocations.Context';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

const FormNewProyect = () => {
    const { createMapLocation } = useMapLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    // Maneja el cambio de archivo
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    // Maneja la búsqueda de ubicación
    const handleSearchChange = async (input) => {
        try {
            const provider = new OpenStreetMapProvider();
            const results = await provider.search({ query: input });
            setSearchResults(results);
        } catch (error) {
            console.error('Error searching location:', error);
        }
    };

    // Maneja la selección de ubicación
    const handleSelectLocation = (result) => {
        setValue('mapCoords', `${result.y}, ${result.x}`);
        setSearchResults([]);
    };

    // Enviar datos al backend
    const onSubmit = async (data) => {
        try {
            if (!formSubmitted) {
                setFormSubmitted(true);
                
                const formData = new FormData();
                formData.append('mapLocationTitle', data.mapLocationTitle);
                formData.append('mapLocationDescription', data.mapLocationDescription);
                formData.append('mapLocationCoords', data.mapCoords);
                
                // Agrega el archivo seleccionado a formData si hay uno
                if (selectedFile) {
                    formData.append('mapLocationImage', selectedFile);
                }

                // Enviar datos al backend
                await createMapLocation(formData);

                // Mostrar éxito o recargar la página
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
            // Maneja errores
        } finally {
            setFormSubmitted(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='FormProfile'>
                <div className='forms-container'>
                    <input
                        type='text'
                        id='mapCoords'
                        placeholder="Coordenadas del proyecto"
                        {...register('mapCoords', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                        style={{ display: 'none' }}
                    />
                    {errors.mapCoords && (
                        <span className='err-msg'>{errors.mapCoords.message}</span>
                    )}

                    <input
                        type='text'
                        id='mapTitle'
                        placeholder="Título del proyecto"
                        {...register('mapLocationTitle', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                    />
                    {errors.mapLocationTitle && (
                        <span className='err-msg'>{errors.mapLocationTitle.message}</span>
                    )}

                    <input
                        type='text'
                        id='searchLocation'
                        placeholder="Buscar ubicación"
                        onChange={(e) => handleSearchChange(e.target.value)}
                        className='search-results-input'
                    />
                    {searchResults.length > 0 && (
                        <div className='search-results'>
                            {searchResults.map((result, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleSelectLocation(result)}
                                >
                                    {result.label}
                                </div>
                            ))}
                        </div>
                    )}

                    <textarea
                        type='text'
                        id='mapLocationDescription'
                        placeholder="Descripción del proyecto"
                        {...register('mapLocationDescription',)}
                        className='input-forms-text-area-vision'
                    />
                    {errors.mapLocationDescription && (
                        <span className='err-msg'>{errors.mapLocationDescription.message}</span>
                    )}

                    <div className='input-forms-img-container'>
                        <label className='input-forms-img-btn'>
                            Cargar imagen
                            <input
                                type='file'
                                id='mapLocationImage'
                                {...register('mapLocationImage')}
                                className='input-forms-img'
                                onChange={handleFileChange}
                            />
                        </label>
                        {selectedFile && (
                            <span className='input-forms-img-file'>
                                {selectedFile.name}
                            </span>
                        )}
                    </div>

                    <button type='submit' className='button-box' disabled={formSubmitted}>
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormNewProyect;
