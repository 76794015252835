import React, { useState } from 'react';
import { useParams, Navigate } from "react-router-dom";
import { useAuth } from "../context/Auth.Context";
import { useMapLocation } from "../context/MapLocations.Context";
import { useMapInfoCard } from '../context/MapLocationsCardInfo.Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../components/navbar";
import Footer from '../components/footer';
import PopupFormat from "../components/popups/popupFormat";
import PopupPicture from "../components/popups/popupFullPicture";
import FormProyect from "../forms/proyectForms/formEditProyects";
import { handlePopupToggle } from "../utils/handlePopup";
import GotTop from "../utils/goTop";
import ProyectCardInfoData from '../components/proyectCards/proyectCardsInfo/proyectCardInfoData';
import FormAddProyectInfo from '../forms/proyectForms/formAddProyectInfo';

const PortafolioProyect = () => {
    const [isInteractionDisabled, setInteractionDisabled] = useState(false);
    const [isProyectPopupOpen, setIsProyectPopupOpen] = useState(false);
    const [isPicturePopupOpen, setIsPicturePopupOpen] = useState(false);
    const [isEditProyectPopupOpen, setIsEditProyectPopupOpen] = useState(false);


    const { mapLocations, loading } = useMapLocation();
    const { user } = useAuth();
    const { proyectoID } = useParams();


    //Reenviar a notfound si no existe el proyecto -->start  
    const proyectVerificator = proyectoID
        ? mapLocations.find(location => location._id === proyectoID)
        : null;

    if (!proyectoID || !proyectVerificator) {
        return <Navigate to="/portafolio" />;
    }
    //Reenviar a notfound si no existe el proyecto -->end

    const proyectContent = proyectoID
        ? mapLocations.find(location => location._id === proyectoID)
        : mapLocations[0];

    const proyectCards = proyectContent.mapInfoCards
        ? proyectContent.mapInfoCards > 0
        : null;

    const handleProyectPopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsProyectPopupOpen, isInteractionDisabled, isProyectPopupOpen);
    };

    const handlePicturePopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsPicturePopupOpen, isInteractionDisabled, isPicturePopupOpen);
    }

    const handleEditProyectPopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsEditProyectPopupOpen, isInteractionDisabled, isEditProyectPopupOpen);
    }

    return (
        <>
            <div className="App">
                <Navbar />
                <GotTop />
                <main className="page-content">
                    <div className="project-page-title">
                        <div className='project-go-back'>
                            <FontAwesomeIcon className='fa-angle-left' icon={faAngleLeft} />
                            <a href='/portafolio' rel='noreferrer noopener'>Volver</a>    
                        </div>
                        <h1 className="project-page-content">{proyectContent.mapLocationTitle}</h1>
                        <hr></hr>
                        <>
                            <div className="project-page-content">
                                {loading ? (
                                    <p>Cargando...</p>
                                ) : (
                                    <>
                                        <img className="project-page-img"
                                            src={proyectContent.mapLocationImage}
                                            onClick={() => handlePicturePopupToggle()}
                                            alt="proyectImage"
                                        />
                                        <PopupPicture
                                            pictureID={proyectContent.mapLocationImage}
                                            togglePopup={handlePicturePopupToggle}
                                            isPopupOpen={isPicturePopupOpen}
                                        />
                                    </>

                                )}
                                {loading ? (
                                    <p>Cargando...</p>
                                ) : (
                                    <>
                                        <p className="project-page-description">{proyectContent.mapLocationDescription}</p>
                                    </>
                                )}
                            </div>
                        </>
                        <>
                            {user && user.role === "TRUE" && (
                                <>
                                    <div className="edit-project-container">
                                        <p
                                            className="edit-project"
                                            onClick={() => isInteractionDisabled ? null : handleProyectPopupToggle(proyectoID)}
                                            disable={isProyectPopupOpen}
                                        >
                                            Editar proyecto
                                        </p>
                                        <PopupFormat
                                            togglePopup={handleProyectPopupToggle}
                                            isPopupOpen={isProyectPopupOpen}
                                            modifyName={"Modificar proyecto"}
                                            formPopup={
                                                <FormProyect
                                                    selectedId={proyectoID}
                                                />}
                                        />
                                    </div>                                    
                                </>
                            )}
                        </>
                    </div>
                    <div className='project-card-info-data-container'>
                        <>
                            {proyectContent.mapInfoCards.map((infoCard) => (
                                <div key={infoCard.id}>
                                    <ProyectCardInfoData
                                        infoCard={infoCard}
                                        proyectID={proyectoID}
                                    />
                                </div>
                            ))}
                        </>
                        <>
                            {user && user.role === "TRUE" && (
                                <div className='add-more-info-container'>
                                    <p
                                        onClick={() => isInteractionDisabled ? null : handleEditProyectPopupToggle(proyectoID)}
                                        disable={isEditProyectPopupOpen}
                                    >
                                        Agregar mas informacion
                                    </p>
                                    <PopupFormat
                                        togglePopup={handleEditProyectPopupToggle}
                                        isPopupOpen={isEditProyectPopupOpen}
                                        formPopup={
                                            <FormAddProyectInfo
                                                selectedId={proyectoID}
                                            />}
                                    />
                                </div>
                            )}

                        </>
                    </div>
                </main >
                <Footer />
            </div >
        </>
    );
};

export default PortafolioProyect;
