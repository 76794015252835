import { createContext, useContext, useEffect, useState } from "react";
import { getContact, getContacts, updateContact } from "../api/contact";

export const ContactContext = createContext();

export const useContact = () => {
    const context = useContext(ContactContext);
    if (!context) {
        throw new Error("Contact must be used within a ContactProvider");
    }
    return context;
};

export const ContactProvider = ({ children }) => {

    const [contacts, setContacts] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    const createContact = async (contact) => {
        try {
            const res = await createContact(contact);
            setContacts((prevContacts) => [...prevContacts, res.data]);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const getContactsData = async () => {
        try {
            const res = await getContacts();
            setContacts(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const getContactById = async (id) => {
        try {
            const res = await getContact(id);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const updateContactData = async (id, contact) => {
        try {
            const res = await updateContact(id, contact);
            setContacts((prevContacts) =>
                prevContacts.map((contact) => (contact._id === id ? res.data : contact))
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        async function checkContacts() {
            try {
                await getContactsData();
            } catch (err) {
                setErrors([err.message]);
            }
        }
        checkContacts();
    }, []);

    return (
        <ContactContext.Provider
            value={{
                contacts,
                errors,
                loading,
                createContact,
                getContacts: getContactsData,
                getContact: getContactById,
                updateContact: updateContactData,
            }}
        >
            {children}
        </ContactContext.Provider>
    );
};