import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSlogan } from "../context/Slogan.Context";
export const FormSlogan = ({ selectedId }) => {
    const { slogan, updateSloganData } = useSlogan();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    
    const Slogan = slogan.find((slogan) => slogan._id === selectedId);
    
    useEffect(() => {
        setValue("content", Slogan.content);
    }, [setValue, Slogan.content, Slogan._id]);
    
    const [formSubmitted, setFormSubmitted] = useState(false);
    
    const onSubmit = async (data) => {
        try {
        if (selectedId && !formSubmitted) {
            setFormSubmitted(true);
            const updatedSlogan = await updateSloganData(selectedId, data);
            console.log(updatedSlogan);
        }
        } catch (error) {
        console.error(error);
        } finally {
        window.location.reload();
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="FormProfile">
            <div className="forms-container">
            <input
                type="text"
                id="content"
                placeholder="Contenido del eslogan"
                {...register("content", {
                required: "Este campo es requerido",
                })}
                className="input-forms"
                defaultValue={Slogan.content}
            />
            {errors.content && (
                <span className="err-msg">{errors.content.message}</span>
            )}
            <button type="submit" className="buttonForm">
                Enviar
            </button>
            </div>
        </div>
        </form>
    );
    }