import React, { useState, useEffect } from 'react';
import { useMapInfoCard } from '../../../context/MapLocationsCardInfo.Context';
import ProyectCardInfoFormat1 from './proyectCardInfoFormats/proyectCardInfoFormat1';
import Loader from '../../loader';
import ProyectCardInfoFormat2 from './proyectCardInfoFormats/proyectCardInfoFormat2';
import ProyectCardInfoFormat3 from './proyectCardInfoFormats/proyectCardInfoFormat3';
import ProyectCardInfoFormat4 from './proyectCardInfoFormats/proyectCardInfoFormat4';
import { Fade } from 'react-awesome-reveal';
const ProyectCardInfoData = ({ infoCard, proyectID }) => {
    const { mapInfoCards } = useMapInfoCard();
    const [mapInfoCard, setMapInfoCard] = useState(null);

    useEffect(() => {
        if (mapInfoCards.length > 0) {
            const foundCard = mapInfoCards.find(card => card._id === infoCard);
            setMapInfoCard(foundCard);
        }
    }, [mapInfoCards, infoCard]);

    if (!mapInfoCard) {
        return <Loader />;
    }

    return (
        <>
            {mapInfoCard.cardFormat === '1' && (
                <Fade
                    cascade
                    triggerOnce={true}
                    direction='up'
                    className="home-card-data"
                >
                    <ProyectCardInfoFormat1
                        cardTitle={mapInfoCard.cardTitle}
                        cardDescription={mapInfoCard.cardDescription}
                        cardImage={mapInfoCard.cardImage}
                        cardFormat={mapInfoCard.cardFormat}
                        cardID={mapInfoCard._id}
                        proyectID={proyectID}
                    />
                </Fade>
            )}

            {mapInfoCard.cardFormat === '2' && (
                <Fade
                    cascade
                    triggerOnce={true}
                    direction='up'
                    className="home-card-data"
                >
                    <ProyectCardInfoFormat2
                        cardTitle={mapInfoCard.cardTitle}
                        cardDescription={mapInfoCard.cardDescription}
                        cardImage={mapInfoCard.cardImage}
                        cardFormat={mapInfoCard.cardFormat}
                        cardID={mapInfoCard._id}
                        proyectID={proyectID}
                    />
                </Fade>
            )}

            {mapInfoCard.cardFormat === '3' && (
                <Fade
                    cascade
                    triggerOnce={true}
                    direction='right'
                    className="home-card-data"
                >
                    <ProyectCardInfoFormat3
                        cardTitle={mapInfoCard.cardTitle}
                        cardDescription={mapInfoCard.cardDescription}
                        cardImage={mapInfoCard.cardImage}
                        cardFormat={mapInfoCard.cardFormat}
                        cardID={mapInfoCard._id}
                        proyectID={proyectID}
                    />
                </Fade>
            )}

            {mapInfoCard.cardFormat === '4' && (
                <Fade
                    cascade
                    triggerOnce={true}
                    direction='left'
                    className="home-card-data"
                >
                    <ProyectCardInfoFormat4
                        cardTitle={mapInfoCard.cardTitle}
                        cardDescription={mapInfoCard.cardDescription}
                        cardImage={mapInfoCard.cardImage}
                        cardFormat={mapInfoCard.cardFormat}
                        cardID={mapInfoCard._id}
                        proyectID={proyectID}
                    />
                </Fade>
            )}
        </>
    );
};

export default ProyectCardInfoData;
