import React, { useEffect, useState } from 'react';
import '../../../src/styles/styles.css';
import { useForm } from 'react-hook-form';
import { useContact } from '../../context/Contact.Context';

export const FormChangeNumber = ({ togglePopup }) => {
    const { contacts, updateContact } = useContact();
    const contact = contacts[0];
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        setValue('number', contact.contactNumber);
        setSelectedId(contact._id);
    }, [setValue, contact.contactNumber, contact._id]);

    const onSubmit = async (data) => {
        try {
            if (selectedId) {
                const updatedContact = await updateContact(selectedId, { contactNumber: data.number });
                console.log(updatedContact);
            }
        } catch (error) {
            console.error(error);
        } finally {
            window.location.reload();
            togglePopup();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='FormProfile'>
                <div className='forms-container'>
                    <input
                        type='text'
                        id='Number'
                        placeholder="Número de contacto"
                        {...register('number', {
                            required: 'Este campo es requerido',
                        })}
                        className='input-forms'
                    />
                    {errors.number && <span className='input-forms-err'>{errors.number.message}</span>}

                    <button type="submit" className="button-box">
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormChangeNumber;