import { createContext, useContext, useEffect, useState } from "react";
import { createMapInfoCard, getMapInfoCards, getMapInfoCard, updateMapInfoCard, deleteMapInfoCard } from "../api/mapLocationsInfo";

export const MapInfoCardsContext = createContext();

export const useMapInfoCard = () => {
    const context = useContext(MapInfoCardsContext);
    if (!context) {
        throw new Error("useMapInfoCard must be used within a MapInfoCardProvider");
    }
    return context;
};

export const MapInfoCardProvider = ({ children }) => {
    const [mapInfoCards, setMapInfoCards] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    const createMapInfoCardData = async (mapInfoCard) => {
        try {
            const res = await createMapInfoCard(mapInfoCard);
            setMapInfoCards((prevCards) => [...prevCards, res.data]);
            return res.data.mapInfoCardId;
        } catch (err) {
            throw err;
        }
    };


    const getMapInfoCardsData = async () => {
        try {
            const res = await getMapInfoCards();
            setMapInfoCards(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const getMapInfoCardById = async (id) => {
        try {
            const res = await getMapInfoCard(id);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const updateMapInfoCardData = async (id, mapInfoCard) => {
        try {
            const res = await updateMapInfoCard(id, mapInfoCard);
            setMapInfoCards((prevCards) =>
                prevCards.map((card) => (card._id === id ? res.data : card))
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteMapInfoCardData = async (id) => {
        try {
            const res = await deleteMapInfoCard(id);
            setMapInfoCards((prevCards) =>
                prevCards.filter((card) => card._id !== id)
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        getMapInfoCardsData();
    }, []);

    return (
        <MapInfoCardsContext.Provider
            value={{
                mapInfoCards,
                errors,
                loading,
                createMapInfoCardData,
                getMapInfoCardById,
                updateMapInfoCardData,
                deleteMapInfoCardData,

            }}
        >
            {children}
        </MapInfoCardsContext.Provider>
    );
};