import axios from './axios';

export const loginRequest = (user) => axios.post('/login', user);

export const verifyTokenRequest = () => axios.get('/verifyToken');

export const updateUser = async (userId, user) => {
    try {
        const res = await axios.put(`/user/${userId}`, user);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error; 
    }
};