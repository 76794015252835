import "../../styles/styles.css";
import React, { useState } from 'react';
import SpecialityFormat1 from "./specialitiesFormats/specialityFormat1";
import SpecialityFormat2 from "./specialitiesFormats/specialityFormat2";
import { useSpecialityCard} from "../../context/SpecialityCards.Context";
import Loader from "../loader";
import { Fade } from "react-awesome-reveal";
import PopupPicture from "../popups/popupFullPicture";
import { handlePopupToggle } from "../../utils/handlePopup";
import PopupFormat from "../popups/popupFormat";
import FormSpeciality from "../../forms/specialityForms/formAddSpeciality";
import { useAuth } from "../../context/Auth.Context";

const SpecialitesData = () => {
    const { SpecialityCards, loading } = useSpecialityCard();
    const { user } = useAuth();
    const [isInteractionDisabled, setInteractionDisabled] = useState({});
    const [isEspecialidadPopupOpen, setIsEspecialidadPopupOpen] = useState(false);

    const handlePicturePopupToggle = (cardID) => {
        setInteractionDisabled((prevState) => ({
            ...prevState,
            [cardID]: !prevState[cardID],
        }));
    };

    const handleEspecialidadPopupToggle = () => {
        handlePopupToggle(
            setInteractionDisabled,
            setIsEspecialidadPopupOpen,
            isEspecialidadPopupOpen,
            isEspecialidadPopupOpen
        );
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="home-cards-container">
                    <div className="home-card-data">
                        {SpecialityCards.map((card, index) => (
                            <div className="home-card-data" key={index}>
                                {card.cardFormat === "1" && (
                                    <Fade
                                        cascade
                                        triggerOnce={true}
                                        direction="left"
                                        className="home-card-data"
                                    >
                                        <SpecialityFormat1
                                            cardTitle={card.cardTitle}
                                            cardContent={card.cardContent}
                                            cardImage={
                                                <div onClick={() => handlePicturePopupToggle(index)}>
                                                    <PopupPicture
                                                        pictureID={card.cardImage}                                                        
                                                        isPopupOpen={isInteractionDisabled[index]}
                                                    />
                                                    <img className="summary-img" src={card.cardImage} alt="sample-img" />
                                                </div>
                                            }
                                            cardID={card._id}
                                            loading={loading}
                                        />
                                    </Fade>
                                )}
                                {card.cardFormat === "2" && (
                                    <Fade
                                        cascade
                                        triggerOnce={true}
                                        direction="right"
                                        className="home-card-data"
                                    >
                                        <SpecialityFormat2
                                            cardTitle={card.cardTitle}
                                            cardContent={card.cardContent}
                                            cardImage={
                                                <div onClick={() => handlePicturePopupToggle(index)}>
                                                    <PopupPicture
                                                        pictureID={card.cardImage}                                                        
                                                        isPopupOpen={isInteractionDisabled[index]}
                                                    />
                                                    <img className="summary-img2" src={card.cardImage} alt="sample-img" />
                                                </div>
                                            }
                                            cardID={card._id}
                                            loading={loading}
                                        />
                                    </Fade>
                                )}
                            </div>
                        ))}
                    </div>
                    {user && user.role === "TRUE" && (
                        <>
                            <p
                                className="home-info-edit"
                                onClick={() => handleEspecialidadPopupToggle()}
                                disabled={isEspecialidadPopupOpen}
                            >
                                Agregar mas especialidades
                            </p>
                            <PopupFormat
                                togglePopup={handleEspecialidadPopupToggle}
                                isPopupOpen={isEspecialidadPopupOpen}
                                modifyName={"Crear nueva especialidad"}
                                formPopup={<FormSpeciality />}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default SpecialitesData;
