import axios from './axios';

export const sendMail = async (formData) => {
    try {
      const res = await axios.post('/mailer', formData);
      return res.data;
    } catch (error) {
      console.error('Error al enviar el correo:', error);
      throw error;
    }
  };