import axios from 'axios';

const portBackend = process.env.REACT_APP_BACKEND_PORT;
const pageIP = process.env.REACT_APP_PAGE_IP;
const baseURL = '/api';

const instance = axios.create({
    baseURL,
});

export default instance;
