import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom'

import HomePage from "./homePage";
import Contacto from "./contacto";
import NotFoundPage from "./notFoundPage";
import PortafolioRoutes from "./portafolioPage.Routes";

import { AuthProvider } from "../context/Auth.Context";

import { SpecialityCardProvider } from "../context/SpecialityCards.Context";
import { ContactProvider } from "../context/Contact.Context";
import { MapLocationProvider } from "../context/MapLocations.Context";
import { VisionProvider } from "../context/Vision.Context";
import { MapInfoCardProvider } from "../context/MapLocationsCardInfo.Context";
import { SloganProvider } from "../context/Slogan.Context";


const MainPage = () => {
    return (
        <div>

            <AuthProvider>

                <SpecialityCardProvider>                    
                        <ContactProvider>
                            <MapLocationProvider>
                                <VisionProvider>
                                    <MapInfoCardProvider>
                                        <SloganProvider>
                                            <Routes>                                                
                                                <Route path='/' element={<HomePage />} />
                                                <Route path='/home' element={<Navigate replace to="/" />} />                                                
                                                <Route path='/portafolio/*' element={<PortafolioRoutes />} />
                                                <Route path='/contacto' element={<Contacto />} />
                                                <Route path="*" element={<NotFoundPage />} />
                                            </Routes>
                                        </SloganProvider>
                                    </MapInfoCardProvider>
                                </VisionProvider>
                            </MapLocationProvider>
                        </ContactProvider>
                </SpecialityCardProvider>

            </AuthProvider>

        </div >
    );
};
export default MainPage;
