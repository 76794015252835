import { Fade } from "react-awesome-reveal";
import React, { useState } from 'react';
import { useVision } from "../context/Vision.Context";
import { useAuth } from "../context/Auth.Context";
import { handlePopupToggle } from "../../src/utils/handlePopup";
import PopupFormat from "../../src/components/popups/popupFormat";
import FormVision from "../forms/formEditVision";

const Vision = () => {
    const { visions, loading } = useVision();
    const { user } = useAuth();
    const [isVisionPopupOpenArray, setIsVisionPopupOpenArray] = useState(visions.map(() => false));
    const [isInteractionDisabledArray, setInteractionDisabledArray] = useState(visions.map(() => false));

    const handleVisionPopupToggle = (index) => {
        handlePopupToggle(
            (newState) => setInteractionDisabledArray([...isInteractionDisabledArray.slice(0, index), newState, ...isInteractionDisabledArray.slice(index + 1)]),
            (newState) => setIsVisionPopupOpenArray([...isVisionPopupOpenArray.slice(0, index), newState, ...isVisionPopupOpenArray.slice(index + 1)]),
            isInteractionDisabledArray[index],
            isVisionPopupOpenArray[index]
        );
    }

    return (
        <>
            <div className="landing-list">
                <div className="landing-visions">
                    <Fade cascade triggerOnce={true} damping={0.1} direction='left' duration={1000}>
                        {visions.map((vision, index) => (
                            <div key={index}>
                                <div className="vision-card-content">
                                    <h3>{vision.title}</h3>
                                    {user && user.role === "TRUE" && (
                                        <>
                                            <p className="vision-edit" onClick={() => handleVisionPopupToggle(index)} disabled={isVisionPopupOpenArray[index]}>
                                                Editar contenido
                                            </p>
                                            <PopupFormat
                                                togglePopup={() => handleVisionPopupToggle(index)}
                                                isPopupOpen={isVisionPopupOpenArray[index]}
                                                modifyName={"Modificar tarjeta de contenido"}
                                                formPopup={
                                                    <FormVision
                                                        selectedId={vision._id}
                                                    />}
                                            />
                                        </>
                                    )}
                                    <p>
                                        {vision.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Fade>
                </div>
            </div>
        </>
    );
};

export default Vision;
