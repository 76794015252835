import { createContext, useContext, useEffect, useState } from "react";
import { createMapLocation, getMapLocation, getMapLocations, updateMapLocation, deleteMapLocation } from "../api/mapLocations";

export const MapLocationContext = createContext();

export const useMapLocation = () => {
    const context = useContext(MapLocationContext);
    if (!context) {
        throw new Error("useMapLocation must be used within a MapLocationProvider");
    }
    return context;
};

export const MapLocationProvider = ({ children }) => {
    const [mapLocations, setMapLocations] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    const setMapLocationData = async (mapLocation) => {
        try {
            const res = await createMapLocation(mapLocation);
            setMapLocations((prevLocations) => [...prevLocations, res.data]);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const getMapLocationsData = async () => {
        try {
            const res = await getMapLocations();
            setMapLocations(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const getMapLocationById = async (id) => {
        try {
            const res = await getMapLocation(id);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const updateMapLocationData = async (id, mapLocation) => {
        try {
            const res = await updateMapLocation(id, mapLocation);
            setMapLocations((prevLocations) =>
                prevLocations.map((location) => (location._id === id ? res.data : location))
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteMapLocationData = async (id) => {
        try {
            await deleteMapLocation(id);
            setMapLocations((prevLocations) =>
                prevLocations.filter((location) => location._id !== id)
            );
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        async function checkMapLocations() {
            try {
                await getMapLocationsData();
            } catch (err) {
                setErrors((prev) => [...prev, err.message]);
            }
        }
        checkMapLocations();
    }, []);

    return (
        <MapLocationContext.Provider
            value={{
                mapLocations,
                errors,
                loading,
                createMapLocation: setMapLocationData,
                getMapLocationById,
                updateMapLocationData,
                deleteMapLocationData,
            }}
        >
            {children}
        </MapLocationContext.Provider>
    );
};