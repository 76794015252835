import React, { useState } from 'react';
import "../styles/styles.css";
import Navbar from "../components/navbar";
import Footer from '../components/footer';
import SpecialitesData from "../components/specialitiesCards/SpecialitiesData";
import Map from "../components/mapComponents/map";
import Vision from "../components/vision";
import GoTop from "../utils/goTop";
import { useSlogan } from "../context/Slogan.Context";
import PopupFormat from "../components/popups/popupFormat";
import { handlePopupToggle } from "../utils/handlePopup";
import { FormSlogan } from '../forms/formChangeSlogan';
import { useAuth } from "../context/Auth.Context";
import { LoaderSlogan } from '../components/loader';
const HomePage = () => {
  const { slogan, loading } = useSlogan();
  const { user } = useAuth();
  const [isSloganInteractionDisabled, setSloganInteractionDisabled] = useState(false);
  const [isSloganPopupOpen, setIsSloganPopupOpen] = useState(false);
  const handleSloganPopupToggle = () => {
    handlePopupToggle(setSloganInteractionDisabled, setIsSloganPopupOpen, isSloganInteractionDisabled, isSloganPopupOpen);
  };
  return (
    <>
      <div className="App">
        <Navbar />
        <GoTop />
        <section className="landing">
          <div className="landing-header">
            <span className='brand-title'><h1>SERVCON</h1></span>
            <span className='brand-subtitle'><h1>Obras Civiles</h1></span>
          {loading ? (
	    <LoaderSlogan />
          ) : (
            <h2>{slogan[0].content}</h2>
          )}
          {user && user.role === "TRUE" && (
            <>
              {loading ? (
                <p>Cargando...</p>
              ) : (
                <div className="edit-project-container">
                  <p
                    className="edit-project"
                    onClick={() => isSloganInteractionDisabled ? null : handleSloganPopupToggle(slogan[0]._id)}
                    disable={isSloganPopupOpen}
                  >
                    Editar lema de la empresa
                  </p>
                  <PopupFormat
                    togglePopup={handleSloganPopupToggle}
                    isPopupOpen={isSloganPopupOpen}
                    modifyName={"Modificar lema de la empresa"}
                    formPopup={
                      <FormSlogan
                        selectedId={slogan[0]._id}
                      />}
                  />
                </div>
              )}
            </>
          )}
          </div>
          <div>
            <Vision />
          </div>
        </section>
        <main className="page-content">
          <SpecialitesData />
          <Map />
        </main>
        <Footer />
      </div>
    </>
  );
};
export default HomePage;
