import React, { useState } from "react";
import { useAuth } from "../context/Auth.Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import PopupFormat from "./popups/popupFormat";
import { handlePopupToggle } from "../utils/handlePopup";
import FormConfirmClose from "../forms/formConfimCloseSession";

const Navbar = () => {
    const { user } = useAuth();
    const closeIcon = <FontAwesomeIcon icon={faXmark} />;
    const [isLoginInteractionDisabled, setLoginInteractionDisabled] = useState(false);
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

    const handlePopupLogin = () => {
        handlePopupToggle(setLoginInteractionDisabled, setIsLoginPopupOpen, isLoginInteractionDisabled, isLoginPopupOpen);
    };


    return (
        <>
            <nav>
                <div className="nav-content">
                    <a href="/home" rel="noreferrer nooopener">
                        <img className="logo-img" alt="logo" src="https://res.cloudinary.com/dsmubyyv9/image/upload/v1700514212/LOGOS/qrtbrjzvgtxdomtr2izs.png" />
                    </a>
                    <ul className="nav-list">
                        <li>
                            <a href="/home" rel="noreferrer noopener">Inicio</a>
                        </li>
                        <li>
                            <a href="/portafolio" rel="noreferrer noopener">Portafolio</a>
                        </li>
                        <li>
                            <a href="/contacto" rel="noreferrer noopener">Contacto</a>
                        </li>
                    </ul>
                </div>
                <div>
                    {user && user.role === "TRUE" && (
                        <div className="admin-access" onClick={() => isLoginInteractionDisabled ? null : handlePopupLogin()} disable={isLoginPopupOpen}>
                            <h3>~ADMIN</h3>
                            <span>
                                {closeIcon}
                            </span>
                            <PopupFormat
                                togglePopup={handlePopupLogin}
                                isPopupOpen={isLoginPopupOpen}
                                formPopup={
                                    <FormConfirmClose
                                        handleClose={handlePopupLogin}
                                    />}
                            />
                        </div>
                    )}
                </div>
            </nav >
        </>
    );
}
export default Navbar;