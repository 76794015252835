import { createContext, useContext, useEffect, useState } from "react";
import { createSpecialityCard, getSpecialityCard, getSpecialityCards, updateSpecialityCard, deleteSpecialityCard } from "../api/specialityCards";

export const SpecialityCardContext = createContext();

export const useSpecialityCard = () => {
    const context = useContext(SpecialityCardContext);
    if (!context) {
        throw new Error("useSpecialityCardmust be used within a SpecialityCardProvider");
    }
    return context;
};

export const SpecialityCardProvider = ({ children }) => {
    const [SpecialityCards, setSpecialityCards] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    const createNewSpecialityCard = async (SpecialityCard) => {
        try {
            const res = await createSpecialityCard(SpecialityCard);
            setSpecialityCards((prevCards) => [...prevCards, res.data]);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const getSpecialityCardsData = async () => {
        try {
            const res = await getSpecialityCards();
            setSpecialityCards(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const getSpecialityCardById = async (id) => {
        try {
            const res = await getSpecialityCard(id);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const updateSpecialityCardData = async (id, SpecialityCard) => {
        try {
            const res = await updateSpecialityCard(id, SpecialityCard);
            setSpecialityCards((prevCards) =>
                prevCards.map((card) => (card._id === id ? res.data : card))
            );
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteSpecialityCardData = async (id) => {
        try {
            await deleteSpecialityCard(id);
            setSpecialityCards((prevCards) =>
                prevCards.filter((card) => card._id !== id)
            );
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        getSpecialityCardsData();
    }, []);

    return (
        <SpecialityCardContext.Provider
            value={{
                SpecialityCards,
                errors,
                loading,
                createSpecialityCard: createNewSpecialityCard,
                getSpecialityCards: getSpecialityCardsData,
                getSpecialityCard: getSpecialityCardById,
                updateSpecialityCard: updateSpecialityCardData,
                deleteSpecialityCard: deleteSpecialityCardData,
            }}
        >
            {children}
        </SpecialityCardContext.Provider>
    );
};