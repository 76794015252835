import React from "react";
import "react-awesome-reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import ExportLinkIcon from '../../src/icons/exportLink.svg';
function topFunction(event) {
    event.preventDefault();
    document.body.scrollTo({ top: 0, behavior: 'smooth' }); // For Safari
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' }); // For Chrome, Firefox, IE and Opera
}
const linkedinIcon = faLinkedinIn;

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="footer-top">
                    <ul className="footer-list">
                        <li>
                            <a className="Logo" href="/home" rel="noreferrer noopener" onClick={topFunction}>
                                <img className="formatLogo logo-img" alt="logo" src="https://res.cloudinary.com/dsmubyyv9/image/upload/v1700514212/LOGOS/qrtbrjzvgtxdomtr2izs.png" />
                            </a>
                        </li>
                        <li>
                            <a href="/contacto">Contacto</a>
                        </li>
                        <li>
                            <a href="/portafolio">Portafolio</a>
                        </li>
                    </ul>
                </div>
                <hr></hr>
                <div className="footer-bottom">
                    <a href="https://www.linkedin.com/company/servcon-obras-civiles/">LinkedIn
                        <img src={ExportLinkIcon} />
                    </a>
                    <a href="https://www.instagram.com/constructora.servcon?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">instagram
                        <img src={ExportLinkIcon} />
                    </a>
                </div>
            </footer>
        );
    }
}

export default Footer;
