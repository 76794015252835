import React, { useState } from 'react';
import { handlePopupToggle } from '../../../../utils/handlePopup';
import PopupFormat from '../../../popups/popupFormat';
import FormCardInfo from '../../../../forms/proyectForms/formEditProyectInfo';
import FormConfirm from '../../../../forms/formConfirmDelete';
import { useAuth } from '../../../../context/Auth.Context';
import Loader from '../../../loader';
import PopupPicture from '../../../popups/popupFullPicture';

const ProyectCardInfoFormat3 = (
    {
        cardTitle,
        cardDescription,
        cardImage,
        cardFormat,
        cardID,
        proyectID
    }
) => {

    const [isInteractionDisabled, setInteractionDisabled] = useState(false);
    const [isContentPopupOpen, setIsContentPopupOpen] = useState(false);
    const [isPicturePopupOpen, setIsPicturePopupOpen] = useState(false);
    const [isDeleteContentPopupOpen, setIsDeleteContentPopupOpen] = useState(false);
    const { user, loading } = useAuth();

    const handlePicturePopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsPicturePopupOpen, isInteractionDisabled, isPicturePopupOpen);
    }

    const handleContentPopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsContentPopupOpen, isInteractionDisabled, isContentPopupOpen);
    }

    const handleDeleteContentPopupToggle = () => {
        handlePopupToggle(setInteractionDisabled, setIsDeleteContentPopupOpen, isInteractionDisabled, isDeleteContentPopupOpen);
    };

    return (
        <>
            <div className='project-cards-container'>
                <div className="ProyectCardsContainerFormat1">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className='project-content'>
                            <p>{cardDescription}</p>
                            <img onClick={() => handlePicturePopupToggle()} src={cardImage} alt={cardTitle} />
                            <PopupPicture
                                pictureID={cardImage}
                                togglePopup={handlePicturePopupToggle}
                                isPopupOpen={isPicturePopupOpen}
                            />
                        </div>
                    )}
                </div>
                {user && user.role === "TRUE" && (
                    <div className='edit-project-options'>
                        <p
                            onClick={() => isInteractionDisabled ? null : handleContentPopupToggle(cardID)}
                            disable={isContentPopupOpen}
                        >
                            Editar informacion
                        </p>
                        <PopupFormat
                            togglePopup={handleContentPopupToggle}
                            isPopupOpen={isContentPopupOpen}
                            formPopup={
                                <FormCardInfo
                                    selectedId={cardID}
                                />}
                        />
                        <p
                            onClick={() => isInteractionDisabled ? null : handleDeleteContentPopupToggle(cardID)}
                            disable={isDeleteContentPopupOpen}
                        >
                            Eliminar informacion
                        </p>
                        <PopupFormat
                            togglePopup={handleDeleteContentPopupToggle}
                            isPopupOpen={isDeleteContentPopupOpen}
                            formPopup={
                                <FormConfirm
                                    selectedId={cardID}
                                    proyectID={proyectID}
                                    handleClose={handleDeleteContentPopupToggle}
                                />}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default ProyectCardInfoFormat3;